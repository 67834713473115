<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import {
	propertyMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Properties",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Multiselect
  },
  data() {
    return {
      title: "Properties",
      properties: [],
      currentPage:1,
      perPage:10,
      rows:0,
      isLoading:false,
      pageLoaded:false,
      empty_config:{
          "title" : this.$t("properties.empty_title"),
          "subtitle" : this.$t("properties.empty_subtitle"),
          "buttonText" : this.$t("properties.new"),
          icon: "fa-cubes"
      },
      
      empty_list_config:{
          "title" : this.$t('properties.empty_search_title'),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      query:'',
      confirmDelete: false,
      property:{
        values:[]
      },
      showPropertyModal:false,
      entities: [
        {
          id: 'customer',
          name: this.$t("properties.entity_customer")
        },
        {
          id: 'product',
          name: this.$t("properties.entity_product")
        }
      ],
      auxEntityFilter: {id: 'all', name: this.$t("common.all")},
      entities_filter:[
      {
          id: 'all',
          name: this.$t("common.all")
        },
        {
          id: 'customer',
          name: this.$t("properties.entity_customer")
        },
        {
          id: 'product',
          name: this.$t("properties.entity_product")
        }
      ],
      submitted: false,
      submittedValue: false,
      auxEntity:null,
      propertyValue:{},
      propertyValues: [],
      property_types: [
        {
            id: 'text',
            name: this.$t("properties.property_types_text")
        },
        {
            id: 'list',
            name: this.$t("properties.property_types_list")
        },
        {
            id: 'number',
            name: this.$t("properties.property_types_number")
        },
        {
            id: 'date',
            name: this.$t("properties.property_types_date")
        },
        {
            id: 'boolean',
            name: this.$t("properties.property_types_boolean")
        },
      ],
      
    }
  },
  mounted() {
    const entity = this.$route.query?.entity;
    if(entity){
      this.auxEntityFilter = this.entities_filter.filter(e=>e.id == entity)[0];
    }
    this.loadProperties();
  },
  computed : {
    
  },
  validations: {
    property: {
        name: { required },
        entity: { required },
        type: { required },
        column_name: { required },
    },
    propertyValue:{
        id: { required },
        value: { required },
    }
  },
  created(){
		this.debounceProperties = _debounce(this.searchProperties.bind(this), 1000);
	},
  methods: {
  ...propertyMethods,

  loadProperties(){

    let loader = this.$loading.show();
    const params={
      q: this.auxEntityFilter.id == 'all' ? `project=${localStorage.getItem('current_project')}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}` : `project=${localStorage.getItem('current_project')}&entity=${this.auxEntityFilter.id}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
    }
    this.isLoading = true;
    this.getProperties(params).then((res)=>{
        this.properties = res.data?.data || [];
        this.rows= res.data?.pagination.totalItems;
        this.pageLoaded = true;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('properties.get_properties_error'),title: this.$t('properties.title') });		
      }).finally(()=>{
        this.isLoading = false
        loader.hide();
      })
  },

  onPropertiesListPageClicked(){
    this.loadProperties();
  },
  
  searchProperties(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t('properties.empty_search_text').replaceAll('#', this.query);

			if(query){
				this.currentPage = 1;
					const params={
							q: `project=${localStorage.getItem('current_project')}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getProperties(params).then((res)=>{
              this.properties = res.data?.data || [];
              this.rows= res.data?.pagination.totalItems;
              this.pageLoaded = true;
            }).catch(()=>{
							this.$notify({ type: 'error', text: this.$t('properties.get_properties_error'),title: this.$t('properties.title') });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
            
			}else{
				this.isLoading = false;
				loader.hide();
				this.currentPage = 1;
				this.loadProperties();
			}
		},

    onResetSearchClicked(){
      this.query = '';
      this.loadProperties();
    },
    
    onRemoveProperty(data){
      this.property = data;
      this.confirmDelete = true;
    },
    onNewPropertyClicked(){
      this.property = {
        values:[]
      };
      this.submitted = false;
      this.showPropertyModal = true;
    },
    onEditPropertyClicked(property){
      this.property = Object.assign({}, property);
      this.property.type = this.property_types.filter(t=>t.id == property.type).length > 0 ? this.property_types.filter(t=>t.id == property.type) : property.type;
      this.auxEntity = this.entities.filter(e=>e.id == property.entity).length > 0 ? this.entities.filter(e=>e.id == property.entity) : property.entity;
      this.showPropertyModal = true;
    },
    onAddValueToSet(){
      this.propertyValues.push({
        id: this.propertyValue.id,
        value: this.propertyValue.value
      });
      this.propertyValue = {};
      this.submittedValue = false;
    },
    onRemoveValue(value){
      this.propertyValues = this.propertyValues.filter(v => v.id !== value.id);
    },
    onConfirmPropertyClicked(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.property.$invalid) {
				return false;
			}
      let loader = this.$loading.show();
      if(this.property._id){
          this.property.type = this.property.type.id;
          this.updateProperty(this.property).then(()=>{
              this.$notify({ type: 'success', text: this.$t('properties.update_property_success'),title:  this.$t('properties.title') });
              this.property = {
                  values:[]
              };
              this.propertyModal = false;
              this.loadProperties();
          }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('properties.update_property_error'),title:  this.$t('properties.title') });
          }).finally(()=>{
              loader.hide();
          });
      }else{
        this.property.source = "gopersonal";
        this.property.project = localStorage.getItem("current_project");
        this.property.type = this.property.type.id;
  
          this.createProperty(this.property).then(()=>{
              this.$notify({ type: 'success', text: this.$t('properties.create_property_success'),title:  this.$t('properties.title') });
              this.property = {
                  values:[]
              };
              this.propertyValues = [];
              this.submitted = false;
              this.submittedValue = false;
              this.auxEntity = null;
              this.propertyValue = {};
              this.showPropertyModal = false;
              this.loadProperties();
          }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('properties.create_property_error'),title:  this.$t('properties.title') });
          }).finally(()=>{  
              loader.hide();
          });
      }
    },
    onCancelPropertyClicked(){
      this.showPropertyModal = false;
      this.property = {
        values:[]
      };
      this.submitted = false;
      this.submittedValue = false;
      this.auxEntity = null;
      this.propertyValue = {};
      this.propertyValues = [];
    },
    onEntityChanged(entity){
      this.property.entity = entity.id;
    },
    onConfirmRemovePropertyClicked(){
      this.confirmDelete = false;
      let loader = this.$loading.show();
      this.deleteProperty(this.property._id).then(()=>{
        this.$notify({ type: 'success', text: this.$t('properties.delete_property_success'),title:  this.$t('properties.title') });
        this.loadProperties();
        this.property = {};
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('properties.delete_property_error'),title:  this.$t('properties.title') });
      }).finally(()=>{
        loader.hide();
      });
    },
    onEntityFilterChanged(){
      this.currentPage = 1;
      this.loadProperties();
    }
  },
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('properties.title')" :items="[]" />
		<div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">  
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                      <multiselect 
                          id="title"
                          v-model="auxEntityFilter"
                          :options="entities_filter"
                          :selectLabel="$t('common.select')"
                          :deselectLabel="$t('common.deselect')"
                          :placeholder="$t('properties.entity_placeholder')"
                          :multiple="false"
                          track-by="id" 
                          label="name"
                          :showNoResults="false"
                          @input="onEntityFilterChanged"
                          />
                      <!-- Search 
                        <div class="search-box me-2 mb-0 d-inline-block">
                          <div class="position-relative">
                              <input
                              type="text"
                              class="form-control"
                              :placeholder="$t('common.search')"
                              @input="debounceProperties($event.target.value)"
                              v-model="query"
                              />
                              <i class="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>-->

                    </div>
                    <div class="col-sm-8">
                        <div class="text-sm-end">
                          <button
                            type="button"
                            class="btn btn-primary mb-0 me-0" 
                            @click="onNewPropertyClicked"
                            v-if="properties?.length>0 || query!=''">
                              <i class="mdi mdi-plus me-1"></i> {{ $t('properties.new') }}
                          </button>
                        </div>
                    </div>
                </div>
                <EmptyList :config="empty_list_config" v-if="!isLoading && properties?.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
                <EmptyList :config="empty_config" v-if=" !isLoading && properties?.length == 0 && query == ''" @onButtonClicked="onNewPropertyClicked" class="mt-3"/>
            </div>
            <div class="table-responsive mb-0" v-if="pageLoaded && properties?.length > 0">
              <table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">{{ $t("properties.name")}}</th>
                    <th scope="col">{{ $t("properties.entity")}}</th>
                    <th scope="col">{{ $t("properties.data_type")}}</th>
                    <th scope="col">{{ $t("properties.type")}}</th>
                    
                    <th scope="col" class="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="property in properties" :key="property.key">
                    <td v-on:click="onEditPropertyClicked(property)">
                      <h5 class="font-size-14 mb-1" >
                        <a href="#" class="text-dark">{{property.name}}</a>
                      </h5>
                    </td>
                    <td>
                      {{$t(`properties.entity_${property.entity}`)}}
                    </td>
                    <td>
                      {{$t(`properties.property_types_${property.type}`)}}
                    </td>
                    <td>
                      {{property.default ? 'Default' : 'Custom' }}
                    </td>
                  <td class="text-end">
                    <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditPropertyClicked(property)" v-if="!property.default"></i>
                    <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveProperty(property)" v-if="!property.default"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
        </div>
        <div class="row mb-3" v-if="rows > perPage">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <b-pagination
                  @input="onPropertiesListPageClicked"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage">
                </b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <b-modal  v-model="showPropertyModal" id="modal-center" hide-footer :title="property._id ? property.name : $t('properties.new')" title-class="font-18">
        <div class="row">
          <div class="mb-3">
              <label for="title">{{ $t("properties.entity")}}*</label>
              <multiselect 
                  id="title"
                  v-model="auxEntity"
                  :options="entities"
                  :selectLabel="$t('common.select')"
                  :deselectLabel="$t('common.deselect')"
                  :placeholder="$t('properties.entity_placeholder')"
                  :multiple="false"
                  track-by="id" 
                  label="name"
                  :showNoResults="false"
                  :class="{ 'is-invalid': submitted && $v.property.entity.$error }"
                  @input="onEntityChanged"
                  :disabled="property._id !=null"
              >
              </multiselect>
              <div v-if="submitted && !$v.property.entity.required" class="invalid-feedback">
                  {{$t("properties.entity_required")}}
              </div>
          </div>
          <div class="mb-3">
              <label for="title">{{ $t("properties.name")}}*</label>
              <input
                  id="title"
                  v-model="property.name"
                  type="text"
                  class="form-control"
                  :placeholder="$t('properties.name_placeholder')"
                  :class="{ 'is-invalid': submitted && $v.property.name.$error }"
              />
              <div v-if="submitted && !$v.property.name.required" class="invalid-feedback">
                  {{$t("properties.name_required")}}
              </div>
          </div>
            <div class="mb-3">
                <label for="title">{{ $t("properties.column_name")}}*</label>
                <input
                    id="title"
                    v-model="property.column_name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('properties.column_name_placeholder')"
                    :class="{ 'is-invalid': submitted && $v.property.column_name.$error }"
                    :disabled="property._id !=null"
                />
                <div v-if="submitted && !$v.property.column_name.required" class="invalid-feedback">
                    {{$t("properties.column_name_required")}}
                </div>
            </div>
            <div class="mb-3">
                <label for="title">{{$t("properties.type")}}*</label>
                <multiselect 
                    v-model="property.type"
                    :options="property_types"
                    :selectLabel="$t('common.select')"
                    :deselectLabel="$t('common.deselect')"
                    :placeholder="$t('properties.type_placeholder')"
                    :multiple="false"
                    :class="{'is-invalid': submitted && $v.property.type.$error,}"
                    track-by="id" 
                    label="name"
                    :showNoResults="false">
                </multiselect>
                <div v-if="submitted && !$v.property.type.required" class="invalid-feedback">
                    {{$t("properties.type_required")}}
                </div>
            </div>
            <div class="mb-3" v-if="property.type?.id=='list'">
                <label for="title">{{$t("properties.values")}}</label>
                <div class="row">
                    <div class="col">
                        <b-form-input size="sm" :placeholder="$t('properties.value_id_placeholder')" v-model="propertyValue.id" :class="{'is-invalid': submittedValue && $v.propertyValue.id.$error,}"></b-form-input>
                        <div v-if="submittedValue && !$v.propertyValue.id.required" class="invalid-feedback">
                            {{$t("properties.value_id_required")}}
                        </div>    
                    </div>
                    <div class="col">
                        <b-form-input size="sm" :placeholder="$t('properties.value_name_placeholder')" v-model="propertyValue.value" :class="{'is-invalid': submittedValue && $v.propertyValue.value.$error,}"></b-form-input>
                        <div v-if="submittedValue && !$v.propertyValue.value.required" class="invalid-feedback">
                            {{$t("properties.value_name_required")}}
                        </div>    
                    </div>
                    <div class="col">
                        <button class="btn btn-sm btn-primary" @click="onAddValueToSet">{{$t("common.add")}}</button>
                    </div>
                </div>
                <div class="row mt-2">
                    <div>
                        <ul class="list-style">
                            <li v-for="value in property.values" :key="value.id"> {{`${value.id} - ${value.value}`}} <i v-on:click="onRemoveValue(value)" class="bx bx-trash text-danger interact"/></li>
                        </ul>
                    </div>    
                </div>
            </div>
        </div>
        <div class="text-end pt-5 mt-3">
            <b-button variant="light" @click="onCancelPropertyClicked">{{$t('common.cancel')}}</b-button>
            <b-button variant="primary" class="ms-1" @click="onConfirmPropertyClicked">{{$t('common.confirm')}}</b-button>
        </div>
        </b-modal>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemovePropertyClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <p>{{$t('properties.remove_property')}}</p>
      </b-modal>
    </div>
    </Layout>
  </template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}
</style>